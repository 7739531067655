var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "기본정보" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-text", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "아차사고명",
                    name: "iimNearAccidentName",
                  },
                  model: {
                    value: _vm.nearAccident.iimNearAccidentName,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "iimNearAccidentName", $$v)
                    },
                    expression: "nearAccident.iimNearAccidentName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    label: "아차사고번호",
                    name: "iimNearAccidentNo",
                  },
                  model: {
                    value: _vm.nearAccident.iimNearAccidentNo,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "iimNearAccidentNo", $$v)
                    },
                    expression: "nearAccident.iimNearAccidentNo",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "발생가능장소",
                    name: "occurrenceLocation",
                  },
                  model: {
                    value: _vm.nearAccident.occurrenceLocation,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "occurrenceLocation", $$v)
                    },
                    expression: "nearAccident.occurrenceLocation",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-datepicker", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    type: "datetime",
                    disabled: _vm.disabled,
                    minuteStep: 10,
                    label: "발생가능일시",
                    name: "occurrenceDt",
                  },
                  model: {
                    value: _vm.nearAccident.occurrenceDt,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "occurrenceDt", $$v)
                    },
                    expression: "nearAccident.occurrenceDt",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-multi-select", {
                  attrs: {
                    required: "",
                    codeGroupCd: "IIM_NEAR_KIND_CD",
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    itemText: "codeName",
                    itemValue: "code",
                    maxValues: "3",
                    name: "iimNearKindCds",
                    label: "아차사고종류",
                  },
                  model: {
                    value: _vm.nearAccident.iimNearKindCds,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "iimNearKindCds", $$v)
                    },
                    expression: "nearAccident.iimNearKindCds",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: {
                    required: true,
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    type: "edit",
                    name: "plantCd",
                  },
                  model: {
                    value: _vm.nearAccident.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "plantCd", $$v)
                    },
                    expression: "nearAccident.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    type: "edit",
                    codeGroupCd: "IIM_GRADE_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "iimGradeCd",
                    label: "아차사고등급",
                  },
                  model: {
                    value: _vm.nearAccident.iimGradeCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "iimGradeCd", $$v)
                    },
                    expression: "nearAccident.iimGradeCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-process", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "공정",
                    name: "processCd",
                  },
                  model: {
                    value: _vm.nearAccident.processCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "processCd", $$v)
                    },
                    expression: "nearAccident.processCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-field", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    data: _vm.nearAccident,
                    label: "보고자",
                    type: "dept_user",
                    name: "reportUserId",
                  },
                  model: {
                    value: _vm.nearAccident.reportUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "reportUserId", $$v)
                    },
                    expression: "nearAccident.reportUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    default: "today",
                    type: "datetime",
                    label: "보고일시",
                    minuteStep: 10,
                    name: "reportDt",
                  },
                  model: {
                    value: _vm.nearAccident.reportDt,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "reportDt", $$v)
                    },
                    expression: "nearAccident.reportDt",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-radio", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    codeGroupCd: "WEATHER_CD",
                    type: "edit",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "weatherCd",
                    label: "날씨",
                  },
                  model: {
                    value: _vm.nearAccident.weatherCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "weatherCd", $$v)
                    },
                    expression: "nearAccident.weatherCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-risk-assess", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    name: "ramRiskAssessmentPlanId",
                  },
                  model: {
                    value: _vm.nearAccident.ramRiskAssessmentPlanId,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "ramRiskAssessmentPlanId", $$v)
                    },
                    expression: "nearAccident.ramRiskAssessmentPlanId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    rows: 6,
                    label: "긴급조치사항",
                    name: "emergencyMeasures",
                  },
                  model: {
                    value: _vm.nearAccident.emergencyMeasures,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "emergencyMeasures", $$v)
                    },
                    expression: "nearAccident.emergencyMeasures",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    rows: 6,
                    label: "아차사고사항개요",
                    name: "overviewAccidentSituation",
                  },
                  model: {
                    value: _vm.nearAccident.overviewAccidentSituation,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.nearAccident,
                        "overviewAccidentSituation",
                        $$v
                      )
                    },
                    expression: "nearAccident.overviewAccidentSituation",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "사고발생시 상태" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-checkbox", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    inline: false,
                    isArray: false,
                    codeGroupCd: "IIM_NEARMISS_DISCERN",
                    itemText: "codeName",
                    itemValue: "code",
                    label: "인지기능",
                    name: "iimNearDiscernCds",
                  },
                  model: {
                    value: _vm.nearAccident.iimNearDiscernCds,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "iimNearDiscernCds", $$v)
                    },
                    expression: "nearAccident.iimNearDiscernCds",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-checkbox", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    inline: false,
                    isArray: false,
                    codeGroupCd: "IIM_NEARMISS_FUNCTION",
                    itemText: "codeName",
                    itemValue: "code",
                    label: "사고기능",
                    name: "iimNearFunctionCds",
                  },
                  model: {
                    value: _vm.nearAccident.iimNearFunctionCds,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "iimNearFunctionCds", $$v)
                    },
                    expression: "nearAccident.iimNearFunctionCds",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-checkbox", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    inline: false,
                    isArray: false,
                    codeGroupCd: "IIM_NEARMISS_FEEL",
                    itemText: "codeName",
                    itemValue: "code",
                    label: "감정",
                    name: "iimNearFeelCds",
                  },
                  model: {
                    value: _vm.nearAccident.iimNearFeelCds,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "iimNearFeelCds", $$v)
                    },
                    expression: "nearAccident.iimNearFeelCds",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-checkbox", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    inline: false,
                    isArray: false,
                    codeGroupCd: "IIM_NEARMISS_EQUIP",
                    itemText: "codeName",
                    itemValue: "code",
                    label: "설비요인",
                    name: "iimNearEquipCds",
                  },
                  model: {
                    value: _vm.nearAccident.iimNearEquipCds,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "iimNearEquipCds", $$v)
                    },
                    expression: "nearAccident.iimNearEquipCds",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-checkbox", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    inline: false,
                    isArray: false,
                    codeGroupCd: "IIM_NEARMISS_MANAGE",
                    itemText: "codeName",
                    itemValue: "code",
                    label: "관리요인",
                    name: "iimNearManageCds",
                  },
                  model: {
                    value: _vm.nearAccident.iimNearManageCds,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "iimNearManageCds", $$v)
                    },
                    expression: "nearAccident.iimNearManageCds",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "발생 가능형태" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-select", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    comboItems: _vm.occFirstItems,
                    type: "edit",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "occurrenceModeLargeCd",
                    label: "대분류",
                  },
                  on: { datachange: _vm.occurDataChange },
                  model: {
                    value: _vm.nearAccident.occurrenceModeLargeCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "occurrenceModeLargeCd", $$v)
                    },
                    expression: "nearAccident.occurrenceModeLargeCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-select", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    comboItems: _vm.occSecItems,
                    type: "edit",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "occurrenceModeSmallCd",
                    label: "중분류",
                  },
                  model: {
                    value: _vm.nearAccident.occurrenceModeSmallCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "occurrenceModeSmallCd", $$v)
                    },
                    expression: "nearAccident.occurrenceModeSmallCd",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
        [
          _c(
            "c-card",
            { attrs: { title: "위험성평가" } },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        label: "빈도",
                        type: "number",
                        name: "frequencyRisk",
                      },
                      on: { dataChange: _vm.dataChange },
                      model: {
                        value: _vm.nearAccident.frequencyRisk,
                        callback: function ($$v) {
                          _vm.$set(_vm.nearAccident, "frequencyRisk", $$v)
                        },
                        expression: "nearAccident.frequencyRisk",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        label: "강도",
                        type: "number",
                        name: "strengthRisk",
                      },
                      on: { dataChange: _vm.dataChange },
                      model: {
                        value: _vm.nearAccident.strengthRisk,
                        callback: function ($$v) {
                          _vm.$set(_vm.nearAccident, "strengthRisk", $$v)
                        },
                        expression: "nearAccident.strengthRisk",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: true,
                        label: "위험도",
                        type: "number",
                        name: "totalRisk",
                      },
                      model: {
                        value: _vm.nearAccident.totalRisk,
                        callback: function ($$v) {
                          _vm.$set(_vm.nearAccident, "totalRisk", $$v)
                        },
                        expression: "nearAccident.totalRisk",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "c-card",
        { attrs: { title: "포상금" } },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "",
                    suffix: "원",
                    type: "number",
                    name: "bounty",
                  },
                  model: {
                    value: _vm.nearAccident.bounty,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "bounty", $$v)
                    },
                    expression: "nearAccident.bounty",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "사고발생 가능 원인" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "직접원인",
                    name: "directCause",
                  },
                  model: {
                    value: _vm.nearAccident.directCause,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "directCause", $$v)
                    },
                    expression: "nearAccident.directCause",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "간접원인",
                    name: "indirectCause",
                  },
                  model: {
                    value: _vm.nearAccident.indirectCause,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "indirectCause", $$v)
                    },
                    expression: "nearAccident.indirectCause",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "기인물",
                    name: "openning",
                  },
                  model: {
                    value: _vm.nearAccident.openning,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "openning", $$v)
                    },
                    expression: "nearAccident.openning",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-text", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "가해물",
                    name: "injurious",
                  },
                  model: {
                    value: _vm.nearAccident.injurious,
                    callback: function ($$v) {
                      _vm.$set(_vm.nearAccident, "injurious", $$v)
                    },
                    expression: "nearAccident.injurious",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "아차사고 관련 사진" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo1,
                    label: "원거리",
                    editable: _vm.editable && !_vm.disabled,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo2,
                    label: "근거리",
                    editable: _vm.editable && !_vm.disabled,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-btngroup" },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                _c("c-appr-btn", {
                  ref: "appr-btn",
                  attrs: {
                    flat: "",
                    name: "danger-journal-appr-btn",
                    editable: _vm.editable,
                    approvalInfo: _vm.approvalInfo,
                  },
                  on: {
                    beforeApprAction: _vm.saveDataAppr,
                    callbackApprAction: _vm.approvalCallback,
                    requestAfterAction: _vm.getDetail,
                  },
                }),
                !_vm.disabled
                  ? _c("c-btn", {
                      attrs: {
                        flat: true,
                        url: _vm.saveUrl,
                        isSubmit: _vm.isSave,
                        param: _vm.nearAccident,
                        mappingType: _vm.saveType,
                        label: "저장",
                        icon: "save",
                      },
                      on: {
                        beforeAction: _vm.saveNearAccident,
                        btnCallback: _vm.saveCallback,
                      },
                    })
                  : _vm._e(),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        !_vm.disabled &&
                        Boolean(_vm.popupParam.iimNearAccidentId),
                      expression:
                        "!disabled && Boolean(popupParam.iimNearAccidentId)",
                    },
                  ],
                  attrs: {
                    flat: true,
                    label: "삭제",
                    editable: _vm.editable,
                    icon: "delete_forever",
                  },
                  on: { btnClicked: _vm.remove },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }