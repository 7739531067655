<template>
  <q-form ref="editForm">
    <c-card title="기본정보" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text
            required
            :editable="editable"
            :disabled="disabled"
            label="아차사고명"
            name="iimNearAccidentName"
            v-model="nearAccident.iimNearAccidentName">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :editable="editable"
            :disabled="true"
            label="아차사고번호"
            name="iimNearAccidentNo"
            v-model="nearAccident.iimNearAccidentNo">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="발생가능장소"
            name="occurrenceLocation"
            v-model="nearAccident.occurrenceLocation">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-datepicker
            required
            :editable="editable"
            type="datetime"
            :disabled="disabled"
            :minuteStep="10"
            label="발생가능일시"
            name="occurrenceDt"
            v-model="nearAccident.occurrenceDt"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-multi-select
            required
            codeGroupCd="IIM_NEAR_KIND_CD"
            :editable="editable"
            :disabled="disabled"
            itemText="codeName"
            itemValue="code"
            maxValues="3"
            name="iimNearKindCds"
            label="아차사고종류"
            v-model="nearAccident.iimNearKindCds"
          >
          </c-multi-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-plant
            :required="true"
            :disabled="disabled"
            :editable="editable"
            type="edit"
            name="plantCd"
            v-model="nearAccident.plantCd" />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            :disabled="disabled"
            type="edit"
            codeGroupCd="IIM_GRADE_CD"
            itemText="codeName"
            itemValue="code"
            name="iimGradeCd"
            label="아차사고등급"
            v-model="nearAccident.iimGradeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-process
            :required="true"
            :editable="editable"
            :disabled="disabled"
            label="공정"
            name="processCd"
            v-model="nearAccident.processCd">
          </c-process>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-field
            :editable="editable"
            :disabled="disabled"
            :data="nearAccident"
            label="보고자"
            type="dept_user"
            name="reportUserId"
            v-model="nearAccident.reportUserId">
          </c-field>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :editable="editable"
            :disabled="disabled"
            default="today"
            type="datetime"
            label="보고일시"
            :minuteStep="10"
            name="reportDt"
            v-model="nearAccident.reportDt"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            :editable="editable"
            :disabled="disabled"
            label="발생부서"
            name="occurrenceDeptCd"
            v-model="nearAccident.occurrenceDeptCd">
          </c-dept>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-radio
            :editable="editable"
            :disabled="disabled"
            codeGroupCd="WEATHER_CD"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="weatherCd"
            label="날씨"
            v-model="nearAccident.weatherCd"
          ></c-radio>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-risk-assess
            :editable="editable"
            :disabled="disabled"
            name="ramRiskAssessmentPlanId"
            v-model="nearAccident.ramRiskAssessmentPlanId">
          </c-risk-assess>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            :rows="6"
            label="긴급조치사항"
            name="emergencyMeasures"
            v-model="nearAccident.emergencyMeasures">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            :rows="6"
            label="아차사고사항개요"
            name="overviewAccidentSituation"
            v-model="nearAccident.overviewAccidentSituation">
          </c-textarea>
        </div>
      </template>
    </c-card>
    <c-card title="사고발생시 상태" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-checkbox 
            :editable="editable"
            :disabled="disabled"
            :inline="false"
            :isArray="false"
            codeGroupCd="IIM_NEARMISS_DISCERN"
            itemText="codeName"
            itemValue="code"
            label="인지기능"
            name="iimNearDiscernCds"
            v-model="nearAccident.iimNearDiscernCds">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-checkbox 
            :editable="editable"
            :disabled="disabled"
            :inline="false"
            :isArray="false"
            codeGroupCd="IIM_NEARMISS_FUNCTION"
            itemText="codeName"
            itemValue="code"
            label="사고기능"
            name="iimNearFunctionCds"
            v-model="nearAccident.iimNearFunctionCds">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-checkbox 
            :editable="editable"
            :disabled="disabled"
            :inline="false"
            :isArray="false"
            codeGroupCd="IIM_NEARMISS_FEEL"
            itemText="codeName"
            itemValue="code"
            label="감정"
            name="iimNearFeelCds"
            v-model="nearAccident.iimNearFeelCds">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-checkbox 
            :editable="editable"
            :disabled="disabled"
            :inline="false"
            :isArray="false"
            codeGroupCd="IIM_NEARMISS_EQUIP"
            itemText="codeName"
            itemValue="code"
            label="설비요인"
            name="iimNearEquipCds"
            v-model="nearAccident.iimNearEquipCds">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-checkbox 
            :editable="editable"
            :disabled="disabled"
            :inline="false"
            :isArray="false"
            codeGroupCd="IIM_NEARMISS_MANAGE"
            itemText="codeName"
            itemValue="code"
            label="관리요인"
            name="iimNearManageCds"
            v-model="nearAccident.iimNearManageCds">
          </c-checkbox>
        </div>
      </template>
    </c-card>
    <c-card title="발생 가능형태" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-select
            :editable="editable"
            :disabled="disabled"
            :comboItems="occFirstItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="occurrenceModeLargeCd"
            label="대분류"
            v-model="nearAccident.occurrenceModeLargeCd"
            @datachange="occurDataChange"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-select
            :editable="editable"
            :disabled="disabled"
            :comboItems="occSecItems"
            type="edit"
            itemText="codeName"
            itemValue="code"
            name="occurrenceModeSmallCd"
            label="중분류"
            v-model="nearAccident.occurrenceModeSmallCd"
          ></c-select>
        </div>
      </template>
    </c-card>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-card title="위험성평가">
          <template slot="card-detail">
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <c-text
                :editable="editable"
                :disabled="disabled"
                label="빈도"
                type="number"
                name="frequencyRisk"
                v-model="nearAccident.frequencyRisk"
                @dataChange="dataChange">
              </c-text>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <c-text
                :editable="editable"
                :disabled="disabled"
                label="강도"
                type="number"
                name="strengthRisk"
                v-model="nearAccident.strengthRisk"
                @dataChange="dataChange">
              </c-text>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <c-text
                :editable="editable"
                :disabled="true"
                label="위험도"
                type="number"
                name="totalRisk"
                v-model="nearAccident.totalRisk">
              </c-text>
            </div>
          </template>
        </c-card>
      </div>
    <c-card title="포상금">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label=""
            suffix="원"
            type="number"
            name="bounty"
            v-model="nearAccident.bounty">
          </c-text>
        </div>
      </template>
    </c-card>
    <c-card title="사고발생 가능 원인" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="직접원인"
            name="directCause"
            v-model="nearAccident.directCause">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="간접원인"
            name="indirectCause"
            v-model="nearAccident.indirectCause">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="기인물"
            name="openning"
            v-model="nearAccident.openning">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="가해물"
            name="injurious"
            v-model="nearAccident.injurious">
          </c-text>
        </div>
      </template>
    </c-card>
    <c-card title="아차사고 관련 사진" class="cardClassDetailForm">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-upload 
            :attachInfo="attachInfo1"
            label="원거리"
            :editable="editable&&!disabled">
          </c-upload>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-upload 
            :attachInfo="attachInfo2"
            label="근거리"
            :editable="editable&&!disabled">
          </c-upload>
        </div>
      </template>
    </c-card>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-appr-btn 
            flat
            ref="appr-btn"
            name="danger-journal-appr-btn"
            :editable="editable"
            :approvalInfo="approvalInfo"
            @beforeApprAction="saveDataAppr"
            @callbackApprAction="approvalCallback"
            @requestAfterAction="getDetail"
          />
          <c-btn
            :flat="true"
            v-if="!disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="nearAccident"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="saveNearAccident"
            @btnCallback="saveCallback"
          />
          <c-btn :flat="true" v-show="!disabled && Boolean(popupParam.iimNearAccidentId)" label="삭제" :editable="editable" icon="delete_forever" @btnClicked="remove" />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'new_nearAccidentInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        iimNearAccidentId: '',
      }),
    },
    attachInfo1: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'NEAR_FIRST_PICTURE',
        taskKey: '',
      }),
    },
    attachInfo2: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'NEAR_SECOND_PICTURE',
        taskKey: '',
      }),
    },
  },
  data() {
    return {
      nearAccident: {
        iimNearAccidentId: '',
        plantCd: '',
        iimNearAccidentNo: '',
        iimNearAccidentName: '',
        iimStatusNearCd: '',
        reportUserId: '',
        reportDt: '',
        iimNearKindCds: '',
        iimGradeCd: null,
        occurrenceDt: '',
        occurrenceDeptCd: '',
        occurrenceLocation: '',
        weather: '',
        emergencyMeasures: '',
        overviewAccidentSituation: '',
        occurrenceModeLargeCd: null,
        occurrenceModeSmallCd: null,
        directCause: '',
        indirectCause: '',
        openning: '',
        injurious: '',
        iimNearDiscernCds: null,
        iimNearFunctionCds: null,
        iimNearFeelCds: null,
        iimNearEquipCds: null,
        iimNearManageCds: null,
        frequencyRisk: '',
        strengthRisk: '',
        totalRisk: '',
        ramRiskAssessmentPlanId: '',
        bounty: 0,
      },
      occFirstItems: [],
      occSecItems: [],
      weatherItems: [],
      editable: true,
      searchUrl: '',
      saveUrl: 'transactionConfig.sop.iim.accident.near.insert.url',
      completeUrl: 'transactionConfig.sop.iim.accident.near.insert.url',
      saveType: 'POST',
      completeType: 'POST',
      isSave: false,
      isComplete: false,
      getUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      updateMode: false,
      isApproval: false,
    };
  },
  computed: {
    disabled() {
      return (this.editable && Boolean(this.nearAccident.iimStatusNearCd) && this.nearAccident.iimStatusNearCd === 'ISNC000002') || this.nearAccident.approvalStatusCd === 'ASC9999999';
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.nearAccident.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.nearAccident.approvalStatusCd, 
        apprEditable: !this.disabled && this.popupParam.iimNearAccidentId, // 결재버튼 활성화 기준
        param: this.nearAccident, // 결재 param
        approvalUrl: transactionConfig.sop.iim.accident.near.complete.url, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000016', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          iimNearAccidentId: this.popupParam.iimNearAccidentId,
          isApprContent: true
        },
        approvalRequestName: '아차사고 ['+this.nearAccident.accidentName+']', // 결재요청명 (문서 title)
        approvalConnId: this.popupParam.iimNearAccidentId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
  },
  watch: {
     // 상세조회를 했을 때 대분류에 따른 중분류의 값을 가져오는 부분
     'nearAccident.occurrenceModeLargeCd'() {
      if (!this.nearAccident.occurrenceModeLargeCd) return;
      this.setSecondItems(this.nearAccident.occurrenceModeLargeCd)
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.iim.accident.near.get.url;
      this.occurUrl = selectConfig.sop.iim.occurForm.list.url;
      this.saveUrl = transactionConfig.sop.iim.accident.near.insert.url;
      this.deleteUrl = transactionConfig.sop.iim.accident.near.delete.url;
      this.completeUrl = transactionConfig.sop.iim.accident.near.complete.url;
      // code setting
      this.setFirstItems();
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.iimNearAccidentId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.iimNearAccidentId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.nearAccident = _result.data;
        },);
      } else {
        this.nearAccident.overviewAccidentSituation =
          '- ' +
          '누가 : \n' + // 누가
          '- ' +
          '누구에게 : \n' + // 누구에게
          '- ' +
          '언제 : \n' + // 언제
          '- ' +
          '어디서 : \n' + // 어디서
          '- ' +
          '무엇을 : \n' + // 무엇을
          '- ' +
          '어떻게 : \n' + // 어떻게
          '- ' +
          '왜 : '
      }
    },
    setFirstItems() {
      this.$http.url = this.occurUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y',
        occurLevel: '1',
      }
      this.$http.request((_result) => {
        this.$_.forEach(_result.data, _item => {
          this.occFirstItems.splice(0, 0, {
            code: _item.iimOccurMasterId,
            codeName: _item.occurName,
          })
        })
      },);
    },
    setSecondItems(iimOccurMasterId) {
      this.$http.url = this.occurUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        iimUpOccurMasterId: iimOccurMasterId,
        useFlag: 'Y',
        occurLevel: '2',
      }
      this.$http.request((_result) => {
        this.occSecItems = [];
        this.$_.forEach(_result.data, _item => {
          this.occSecItems.splice(0, 0, {
            code: _item.iimOccurMasterId,
            codeName: _item.occurName,
          })
        })
      },);
    },
    saveNearAccident() {
      if (this.popupParam.iimNearAccidentId) {
        this.saveUrl = transactionConfig.sop.iim.accident.near.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.iim.accident.near.insert.url;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.nearAccident.regUserId = this.$store.getters.user.userId
              this.nearAccident.chgUserId = this.$store.getters.user.userId   
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      
      if (!this.popupParam.iimNearAccidentId) {
        this.$emit('changeStatus', result.data)
        this.popupParam.iimNearAccidentId = result.data;
      }
      this.getDetail();

    },
    /* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.iimNearAccidentId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup', 'REMOVE');  
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    closePopUps() {
      this.$emit('closePopup')
    },
    dataChange() {
      if(this.nearAccident.frequencyRisk !== null && this.nearAccident.frequencyRisk >= 0 && this.nearAccident.strengthRisk !== null && this.nearAccident.strengthRisk >= 0) {
      this.nearAccident.totalRisk = this.nearAccident.frequencyRisk * this.nearAccident.strengthRisk
      }
    },
    /* eslint-disable no-unused-vars */ 
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        // 결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?
        message: '결재요청 하기 전 입력된 값을 저장합니다.\n\r 진행하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.nearAccident.regUserId = this.$store.getters.user.userId
          this.nearAccident.chgUserId = this.$store.getters.user.userId

          let promises = [
            {
              func: this.approvalValue
            },
          ];
          this.$comm.orderedPromise(promises);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    occurDataChange() {
      if (!this.nearAccident.occurrenceModeLargeCd){
        this.nearAccident.occurrenceModeSmallCd = null;
        this.occSecItems = [];
      } else {
        this.nearAccident.occurrenceModeSmallCd = null;
        this.setSecondItems(this.nearAccident.occurrenceModeLargeCd)
      }
    },
  }
};
</script>
